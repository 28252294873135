'use client';

import React from 'react';
import Link from 'next/link';
import { Result } from 'antd';
import { AppRoutes } from '@shared/constants/appRoutes';

export default function NotFound() {
  return (
    <Result
      title="404"
      status="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Link href={AppRoutes.base}>Back Home</Link>}
    />
  );
}
